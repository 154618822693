import $ from 'jquery';
import stickyFooter from 'sticky-footer';
import SmoothScroll from 'smooth-scroll';
import wowjs from 'wowjs';
import Headroom from "headroom.js";
import slickCarousel from "slick-carousel";
import Tabby from 'tabbyjs';
import LazyLoad from "vanilla-lazyload";
import fancybox from '@fancyapps/fancybox';
import addBrowserClass from './modules/browser';

// -------------------------------------------------------------------
// stickyFooter JS
// -------------------------------------------------------------------
stickyFooter.init({
    selectorWrap: '[data-sticky-wrap]', // Selector for the wrap container (must use a valid CSS selector)
    selectorFooter: '[data-sticky-footer]', // Selector for the footer (must use a valid CSS selector)
    callback: function() {}, // Runs after the footer is stuck
});
// -------------------------------------------------------------------
// slick-carousel JS
// -------------------------------------------------------------------
$(".p-profBody__Photo-wrapper").slick({
    // normal options...
    infinite: true,
    prevArrow: '<div class="p-profBody__Photo-prev p-profBody__Photo-icon"><i class="fas fa-arrow-alt-circle-left"></i></div>',
    nextArrow: '<div class="p-profBody__Photo-next p-profBody__Photo-icon"><i class="fas fa-arrow-alt-circle-right"></i></div>',
    speed: 500,
    dots: true,
    dotsClass: 'p-profBody__Photo-dots'
});
// -------------------------------------------------------------------
// SmoothScroll JS
// -------------------------------------------------------------------
const scroll = new SmoothScroll('a[href*="#"]');

// -------------------------------------------------------------------
// headroom JS
// -------------------------------------------------------------------
// const header = document.querySelector(".l-header");
// const headroom = new Headroom(header);
// headroom.init();
const header = document.querySelector('.l-header');
const headroom = new Headroom(header, {
    //上から120pxより下の領域で10px以上スクロールするとイベントが発動する
    "offset": 100,
    "tolerance": 10,
    "classes": {
        "initial": "initial"
    }
});
headroom.init();
// -------------------------------------------------------------------
// WOWJS
// -------------------------------------------------------------------
window.WOW = require('wowjs').WOW;
new WOW({
    mobile: false
}).init();
// -------------------------------------------------------------------
// Tabby JS
// -------------------------------------------------------------------
const tabsElem = document.querySelector("[data-tabs]");
if (tabsElem === null) {} else {
    var tabs = new Tabby('[data-tabs]');
}
// -------------------------------------------------------------------
// jQuery
// -------------------------------------------------------------------
$(function() {
    $('.is-jqAttach').each(function(i, elem) {
        var str = $(elem).attr('href');
        str = str.replace('/category/', '/blog/category/');
        $(elem).attr('href', str);
    });
    // プルダウン変更時に遷移
    $('select[name=selectorArchives]').change(function() {
        if ($(this).val() != '') {
            window.location.href = $(this).val();
        }
    });
    $(".l-header__Trigger").fancybox({
        iframe: {
            css: {
                width: '600px'
            }
        }
    });
});